.jk-die {
  position: relative;
  display: inline-block;
  margin: 15px;
  --jk-die-size: 60px;
}

.boxes .jk-die {
    margin: 1px;
    z-index: 100;
    float: left;
}
.box:hover .jk-die { opacity: 0.2; }

.jk-die.strength {--jk-die-color: #883; }
.jk-die.agility  {--jk-die-color: #838; }
.jk-die.magic    {--jk-die-color: #338; }
.jk-die.heroic   {--jk-die-color: #333; }
.jk-die.poison   {--jk-die-color: #383; }

.jk-sideface { filter: brightness(40%); }
.jk-die--animated .jk-topface { animation: jk-rolllight 1.5s linear 1; }
@keyframes jk-rolllight {
  0% { filter: brightness(40%); }
  80% { filter: brightness(40%); }
  100% { filter: none; }
}

.jk-die-body {
  transform-style: preserve-3d;
  transition: transform 0.4s ease-out;
  width: var(--jk-die-size);
  height: var(--jk-die-size);
}

.jk-die--animated .jk-die-body {
  animation: jk-rolling 1.5s linear 1;
}

.jk-shadow {
  --color: rgba(255, 255, 255, 0.1);

  position: absolute;
  top: calc(var(--jk-die-size) * 0.1);
  left: calc(var(--jk-die-size) * 0.1);
  transform: translateZ(calc(var(--jk-die-size) * -1));
  transition: transform 0.4s ease-out;
  border-radius: calc(var(--jk-die-size) * 0.1);
  box-shadow: 0 0 10px var(--color);
  background: var(--color);
  width: calc(var(--jk-die-size) * 1.1);
  height: calc(var(--jk-die-size) * 1.0);
}

.jk-die--animated .jk-shadow {
  animation: jk-shadow-spin 1.5s linear 1;
}

@keyframes jk-rolling {
  0% {
    transform: rotateX(90deg) rotateY(90deg) rotateZ(-90deg) translateX(0);
  }

  3% {
    transform: rotateX(-90deg) rotateY(-90deg) rotateZ(90deg) translateX(0);
  }

  6% {
    transform: rotateX(90deg) rotateY(180deg) rotateZ(-90deg) translateX(0);
  }

  12% {
    transform: rotateX(-180deg) rotateY(90deg) rotateZ(360deg) translateX(0);
  }

  24% {
    transform: rotateX(90deg) rotateY(-180deg) rotateZ(-90deg) translateX(0);
  }

  40% {
    transform: rotateX(-90deg) rotateY(-90deg) rotateZ(90deg) translateX(0);
  }
}

@keyframes jk-shadow-spin {
  0% {
    transform: translateY(0) rotateZ(-90deg) scale(1);
    opacity: 0.5;
    filter: blur(5px);
  }

  3% {
    transform: translateY(0.2em) rotateZ(90deg) scale(0.85);
  }

  6% {
    transform: translateY(0.4em) rotateZ(-90deg) scale(0.7);
  }

  12% {
    transform: translateY(0.8em) rotateZ(360deg) scale(0.6);
  }

  24% {
    transform: translateY(0.9em) rotateZ(-90deg) scale(0.5);
  }

  40% {
    transform: translateY(0.9em) rotateZ(90deg) scale(0.5);
    opacity: 0.5;
    filter: blur(3px);
  }
}

.jk-selected .jk-shadow {
  --color1: rgba(255, 255, 255, 0.1);
  --color2: rgba(255, 255, 255, 0.6);
  animation: shadow-pulsing 0.9s infinite alternate;
}

.jk-warn .jk-shadow {
  --color1: rgba(255, 0, 0, 0.1);
  --color2: rgba(255, 0, 0, 0.6);
  animation: shadow-pulsing 0.9s infinite alternate;
}

.jk-good .jk-shadow {
  --color1: rgba(0, 180, 0, 0.6);
  --color2: rgba(0, 180, 0, 1);
  animation: shadow-pulsing 0.9s infinite alternate;
}

@keyframes shadow-pulsing {
  0% {
    top: calc(var(--jk-die-size) * 0.1);
    left: calc(var(--jk-die-size) * 0.1);
    width: calc(var(--jk-die-size) * 1.1);
    height: calc(var(--jk-die-size) * 1.0);
    box-shadow: 0 0 2px 0.1em var(--color1);
    background: var(--color1);
  }
  100% {
    left: calc(var(--jk-die-size) * -0.05);
    top: calc(var(--jk-die-size) * -0.00);
    width: calc(var(--jk-die-size) * 1.3);
    height: calc(var(--jk-die-size) * 1.2);
    box-shadow: 0 0 5px 0.1em var(--color2);
    background: var(--color2);
  }
}

.jk-face,
.jk-inner-face,
.jk-internal {
  position: absolute;
  border-radius: calc(var(--jk-die-size) * 0.3);
  background-color: var(--jk-die-color);
  overflow: hidden;
}

.jk-face,
.jk-inner-face {
  width: var(--jk-die-size);
  height: var(--jk-die-size);
}

.jk-internal {
  width: calc(var(--jk-die-size) * 0.98);
  height: calc(var(--jk-die-size) * 0.98);
}

.jk-pip-face {
  background: radial-gradient(
      closest-side,
      rgba(0, 0, 0, 0.2),
      var(--jk-die-color)
    ),
    var(--jk-die-color);
  fill: white;
}
