/***********************
 *  GENERAL
 ***********************/

html, body, .bgio-client {
    background-color: black;
    width: 1800px;
    height: 2100px;
}

.activemove {
    animation: pulse-animation 3s linear infinite;
    --pulse-color1: #fff8;
    --pulse-color2: #fff;
}

.warnmove {
    --pulse-color1: #f808;
    --pulse-color2: #f80;
}

.critmove {
    --pulse-color1: #f008;
    --pulse-color2: #f00;
}

@keyframes pulse-animation {
    15% { box-shadow: 0 0 0  5px var(--pulse-color1), 0 0 2px 4px var(--pulse-color2); }
    30% { box-shadow: 0 0 0 10px var(--pulse-color1), 0 0 2px 4px var(--pulse-color2); }
    50% { box-shadow: 0 0 0 30px               #0000, 0 0 2px 4px var(--pulse-color2); }
    95% { box-shadow: 0 0 0 30px               #0000, 0 0 2px 4px var(--pulse-color2); }
}

.activemove:after {
  content: '';
  position: absolute;
  left: 0; top: 0;
  background: white;
  opacity: 0;
  animation: fade-animation 3s linear infinite;
}
.warnmove:after { background: orange; }
.critmove:after { background: red; }

@keyframes fade-animation {
    15% { opacity: 0.2; }
    30% { opacity: 0; }
}

.inactive { opacity: 0.2 }
.inactive .inactive { opacity: 1 }

/***********************
 *  BOARD              *
 ***********************/

.table {
    background: #030;
    border: 15px ridge brown;
    width: 2700px;
    height: 1600px;
    transform: scale(60%);
    transform-origin: 0 0;
    position: relative;
}

.dungeons {
    position: absolute;
    right: 565px;
    width: 1100px;
}
.decks {
    position: absolute;
    right: 565px;
    bottom: 0;
    width: 1100px;
}
.playarea {
    position: absolute;
    left: 0;
    width: 1000px;
}
.levels {
    position: absolute;
    right: 0;
    width: 550px;
}

/***********************
 *  CARD               *
 ***********************/

.card {
    position: relative;
    width: 520px;
    height: 380px;
    border-radius: 15px;
    margin: 10px;
    display: inline-block;
    box-sizing: border-box;
    background-size: 567px;
    background-position: center center;
    background-repeat: no-repeat;
    border: 2px solid #888;
}
.card.empty { background: #242; }


.deck { box-shadow: 8px 5px; }
.discard { box-shadow: 8px 5px; }
.full.back { background-image: url("./img/back.png"); }
.full.forest.front { background-image: url("./img/forest.png"); }
.full.odd.front { background-image: url("./img/odd.png"); }

.label {
    position: absolute;
    width: 200px;
    text-align: center;
    color: grey;
    text-shadow: 4px 4px 2px black;

    left: 50px;
    top: 63px;
    font-size: 3rem;
    display: none;
}
.size {
    left: 260px;
    top: 33px;
    font-size: 6rem;
}
.decks .label { display: initial; }
.cardzwrap {
    position: relative;
    width: 260px;
    height: 191px;
    display: inline-block;
    margin: 5px;
}
.cardzwrap:hover { scale: 2; z-index: 1000; }
.cardz {
    transform: scale(50%);
    transform-origin: 0 0;
    display: block;
}
.cardz > .size { display: none; }


.carddata {
    position: absolute;
    text-align: center;
}

/***********************
 *  HEADER             *
 ***********************/

.encounter {
    left: 70px;
    top: 23px;
    width: 30px;
    height: 30px;
}
.encounter:after { font-size: 1.2rem; }
.combat .encounter:after { content: '⚔️'; }
.peril .encounter:after { content: '🪤'; }

.name {
    left: 100px;
    top: 29px;
    width: 170px;
    height: 24px;
    color: white;
    font-variant: small-caps;
    font-weight: bold;
    letter-spacing: 2px;
    font-stretch: expanded;
}
.set {
    left: 250px;
    top: 28px;
    width: 24px;
    height: 24px;
    font-size: 1rem;
}
.xps {
    right: 24px;
    top: 8px;
    width: 170px;
    height: 32px;
    font-size: 1.5rem;
    font-weight: bold;
    color: gold;
    text-align: right;
}
.xp {
    float: right;
    width: 32px;
    height: 32px;
}
.xp:after { font-size: 1.5rem; content: '🔥'; }

/***********************
 *  ITEM               *
 ***********************/

.items {
    left: 0px;
    top: 69px;
    height: 200px;
}
.item {
    position: relative;
    left: 0px;
    width: 65px;
    height: 40px;
}

.itemy { top:  0px; }
.itemp { top: 12px; }
.itemb { top: 24px; }
.itemh { top: 36px; }

.itemicon { display: inline; }
.itemicon:after { font-size: 2rem; text-shadow: 1px 1px black; }

.itemyicon:after { content: "🗡️"; }
.itempicon:after { content: "👢"; }
.itembicon:after { content: "🌀"; }
.itemhicon:after { content: "❤️"; }
.itemhicon.used:after { content: "🖤"; }

.item.c5 {
    transform: scaleX(78%);
    transform-origin: 0 50%;
}
.item.c6 {
    transform: scaleX(65%);
    transform-origin: 0 50%;
}

/***********************
 *  SKILL              *
 ***********************/

.skill {
    bottom: 30px;
    left: 80px;
    width: 394px;
    height: 60px;
    padding: 2px;
}

.skillcost, .skilltype {
    bottom: 4px;
    left: 10px;
    height: 48px;
    width: 48px;
    font-size: 2rem;
}
.skilltype.👢, .skilltype.🗡️  {
    border: 2px solid black;
    background: #fff3;
    height: 44px;
    padding-top: 4px;
    transform: scale(57%);
    transform-origin: 0 50%;
}
.skilltype { text-shadow: 0 0 4px black; }
.skilltype.🌀 { text-align: left; }
.skillcost { text-align: right; }
.skilltype.❤️ { text-align: center; transform: scale(120%); }
.skillcost.❤️ { text-align: center; transform: scale(90%); bottom: 8px; font-weight: bold;}
.skilldesc {
    border-left: 5px groove tan;
    bottom: 2px;
    left: 63px;
    height: 56px;
    width: 320px;
    text-align: left;
    padding-left: 4px;
    text-shadow: 0 0 0.2px black;
    line-height: 1.1;
}
.skillname {
    bottom: 0px;
    right: 65px;
    height: 24px;
    width: 200px;
    text-align: right;
    font-weight: bold;
}
.skilluse {
    bottom: 0px;
    right: 10px;
    height: 24px;
    width: 60px;
}
.use {
    position: relative;
    float: right;
    height: 20px;
    display: inline;
}
.use.true {
    width: 20px;
}
.use:before {
    position: absolute;
    font-size: 1rem;
    text-shadow: 0 0 4px black;
}
.combat.true:before { content: '⚔️'; }
.peril.true:before  { content: '🪤'; }
.noboss.true:before { content: '💀'; }
.noboss.true:after {
    position: absolute;
    font-size: 1rem;
    content: "\274c"; /* use the hex value here... */
}

/***********************
 *  EFFECTS            *
 ***********************/

.cardimage {
    top: 50px;
    left: 60px;
    height: 220px;
    width: 220px;
    color: goldenrod;
    font-size: 4.7rem;
    text-shadow: 2px 2px 3px #000, -2px -2px 3px #fff;
    padding: 20px;
    box-sizing: border-box;
}

.ebox { margin-bottom: 5px;}
.effects {
    top: 50px;
    left: 280px;
    height: 220px;
    width: 220px;
}

.effect {
    width: 210px;
    background: lightgray;
    border: 5px ridge gray;
}
.effect.true { display: none; }
.combat .effect { height: 63px; }
.peril .effect { height: 20px; margin-bottom: 4px;}
.ename { font-weight: bold; }
.edesc { font-size: 0.8em; text-wrap: balance; }
.etimecost { display: inline; }
.etimecost:after { content: '⏳'; text-shadow: 1px 1px 3px black; }

.boxes { line-height: 0px; }
.box {
    height: 63px;
    border-width: 5px;
    border-style: ridge;
    display: inline-block;
    position: relative;
    line-height: initial;
    border-color: #000c;
    background: #fffc;
}
.box.small { width: 63px; }
.box.big { width: 136px; }

.box.🗡️ { border-color: #ff0c; background: #ffcc; }
.box.👢 { border-color: #f0fc; background: #fcfc; }
.box.🌀 { border-color: #08fc; background: #ccfc; }

.hp {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 2em;
    font-weight: bold;
    padding-right: 5px;
    color: #0008;
    text-shadow: 1px 1px 1px #fff, -1px -1px 1px #000;
}

.cons {
    text-align: left;
    padding: 3px;
    height: 63px;
    overflow: hidden;
    position: absolute;
    top: 0; left: 0;
}
.emoji {
    font-size: 2rem;
    text-shadow: 1px 1px 3px black;
}
.box.small .cons { width: 30px; }
.box.big .cons { width: 100px; }
.con {
    width: 25px;
    display: inline-block;
    overflow: hidden;
}
.con:after {
    font-size: 1.4rem;
    text-shadow: 1px 1px 3px black;
}
.con.a:after { content: '🛡️'; }
.con.d:after { content: '💧'; }
.con.h:after { content: '❤️'; }
.con.t:after { content: '⏳'; }
.con.x:after { content: '💀'; }

.arrow.left, .arrow.down {
    display: inline-block;
    height: 73px;
    width: 42px;
    position: relative;
    text-align: left;
    line-height: initial;
}
.arrow.left:after, .arrow.down:after {
    padding-left: 0.2em;
    font-size: 4em;
    color: #9cf;
    position: absolute;
    top: 30;
}

.arrow.left:after {  content: '🠸'; }
.arrow.down:after {  content: '🢛'; }

/***********************
 *  HERO               *
 ***********************/

.hero {
    position: relative;
    width: 520px;
    height: 720px;
    border-radius: 15px;
    margin: 10px;
    display: inline-block;
    box-sizing: border-box;
    background-size: 525px;
    background-position: center center;
    background-repeat: no-repeat;
    border: 2px solid #888;
}
.hero.forest { background-image: url("./img/foresthero.png"); }
.hero.odd { background-image: url("./img/oddhero.png"); }

.playarea > .hero, .levels > .card {
    position: absolute;
    top: 0px;
    z-index: 10;
}

.playarea > .hero { right: 0px; }
.levels > .card { left: 0px; top: 168px; }

.herocards { position: absolute; }
.herocards.heroitems { right: 0px; top: 23px; }
.herocards.heroskills { right: 0px; top: 743px; }
.herocards.xpcards { left: 0px; top: 168px; }
.herocards.potions { left: 0px; top: 168px; }

.herocard { position: absolute; }
.herocards.heroitems .herocard { right: 65px; }
.herocards.heroskills .herocard { right: 0px; bottom: -104px; }
.herocards.xpcards .herocard { left: 0px; top: -42px; }
.herocards.potions .herocard { left: 0px; top: 104px; }

.herozwrap {
    position: relative;
    width: 260px;
    height: 360px;
    display: inline-block;
    margin: 5px;
}
.herozwrap:hover { scale: 2; z-index: 1000; }
.heroz {
    transform: scale(50%);
    transform-origin: 0 0;
    display: block;
}

.hero .name {
	left: 205px;
	top: 35px;
	width: 207px;
	height: 35px;
	color: gold;
	letter-spacing: 5px;
	font-size: 1.9em;
    font-weight: bolder;
}
.hero .item { width: 200px; text-align: left; }
.hero .items { top: 93px; left: 10px; }
.hero .skill { left: 64px; bottom: 34px;}

.hero .item.itemd {
    top: 60px;
    left: 22px;
    width: 70px;
}
.itemdicon:after { content: "💧"; }
.itemd.c5, .itemd.c6 { transform: none; }

.skill.heroic { bottom: 130px; }
.heroic .skilldesc { height: 67px; width: 380px; left: 3px; border: none; }
.heroic .skilldesc:before {
    content: "Heroic: ";
    font-weight: bold;
}

/***********************
 *  DICE               *
 ***********************/

.dicebox {
    position: absolute;
    left: 0;
    top: 420px;
    width: 450px;
    direction: rtl;
}

/***********************
 *  DUNGEON FLOORS
 ***********************/

.decks .dungeon {
    position: absolute;
    left: 0;
}

.decks .dungeon:has(.name:hover) {
    z-index: 10;
}

.decks .dungeon.f1 { top: -204px; }
.decks .dungeon.f2 { top: -281px; }
.decks .dungeon.f3 { top: -359px; }

.full.dungeon.front { background-image: url("./img/dungeon.png"); }
.dungeon .name {
    left: 100px;
    top: 10px;
    width: 320px;
    height: 80px;
    line-height: 70px;
    font-size: 1.5rem;
    color: black;
    background-size: 320px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("./img/scroll.png");
}
.dungeon .encounter {
    top: 88px;
    height: 30px;
    font-size: 1.2rem;
    color: white;
    width: 100px;
	letter-spacing: 2px;
	word-spacing: 10px;
}
.dungeon .encounter.peril { left: 20px; word-spacing: 5px; letter-spacing: 1px; }
.dungeon .encounter.combat { left: 380px; }

.dungeon .effects {
    top: 125px;
    left: 34px;
    width: 483px;
    text-align: left;
}
.dungeon .effect {
    width: 238px;
    height: 63px;
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 3.5px;
}
.dungeon .ebox { margin-bottom: 0; }
.dungeon .ename { margin-left: 5px; }
.dungeon .edesc {
    text-align: right;
    padding: 10px 10px 10px 25px;
}
.dungeon .boxes {
    display: inline-block;
}
